import React, { useEffect } from "react";
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import MaterialTable from "material-table";
import { stElemnt } from "../../../../St/Reviews/cStyle";
import { mt } from "../../../../St/comps/cStyle";
import { reFormatDate } from "../../../../utils/utils";
import { colors, Container, makeStyles, Typography } from "@material-ui/core";

export function Totals({ data }) {
  const cls = useRptAF();
  return (

    <Grid
    container
    lg={12}
    md={12}
    
    style={{paddingTop:"0.5rem",paddingBottom:"0.5rem", backgroundColor: colors.grey[100],fontFamily: "Amiri-Regular"  }}
  >
    <Grid container xs={12} /*className={cls.rootchip}*/  style={{ paddingBottom: 5, 
      backgroundColor: colors.grey[100],fontFamily: "Amiri-Regular",
      border: "solid 1px",
      padding: "0.3rem 0.1rem 0.3rem 0.1rem", 
      borderRadius: "0px 0px 5px 0px" }}>
           <Grid item xs={3} className={[cls.chipVMU]}></Grid>

      <Grid item xs={2} className={[cls.chipVMU]}>
        {Rept.total_dept?.toFixed(2)}
      </Grid>
      <Grid item xs={2} className={[cls.chipVMU]}>
        {Rept.total_credit?.toFixed(2)}
      </Grid>
      <Grid item xs={4} className={[cls.chipTMU]} >
        {"الاجمالي"}
      </Grid>
    </Grid>
  </Grid>
  );
}
function BodyRest({ data, isFullPage, isLast }) {
  const cls = useRptAF();
  const cellS = {

    padding: "0px 0px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    border: "solid 0.1px",
    fontSize: "0.55rem",
    width: "350px",
    fontFamily: "Amiri-Regular",
    fontWeight:"bold",
  };
  var columns = [
    // { title: " م", field: "" },
    { title: "كود الحساب", field: "acc_no" },

    { title: "اسم الحساب", field: "name" },
    
    { title: "مدين", field: "dept" ,},
    { title: "دائن", field: "credit" ,},
    {
      title: "البيان",
      field: "notes",
      cellStyle: {
        
        padding: "5px 0px",
        textAlign: "-webkit-center",
        margin: "0px",
        whiteSpace: "normal",
        width: "200px",
        fontWeight:"bold",
        border: "solid 0.1px",
        fontFamily: "Amiri-Regular",
        fontSize: "0.7rem",
      },
    },
  ];
  var columns2 = [
    { title: "الرصيد", field: "balance" },
    { title: "مدين", field: "dept" },
    { title: "دائن", field: "credit" },
    { title: "رقم المستند", field: "doc_no" },
    {
      title: "تاريخ المستند",
      field: "date",
      render: (expense) => reFormatDate(expense.date),
    },
    { title: "رقم المرجع", field: "demoo" },
    {
      title: "البيان",
      field: "notes",
      cellStyle: cellS,
    },
  ];


  const data2 = data.map((row) => {
    return {
      ...row,
      dept: row.dept.toFixed(2),
      credit: row.credit.toFixed(2),
    };
  });
  return (
    <Grid item container style={{fontFamily: "Amiri-Regular"}}>
      <Grid item container style={{ direction: "rtl",paddingTop:"0.7rem"}}>
        <Grid
          item
          xs={12}
          // style={{
          //   maxHeight: isFullPage ?   "59rem": "42.5rem",
          //   minHeight: isFullPage ?  "59rem": "42.5rem",
          // }}
        >
          <MaterialTable
            style={{ boxShadow: "none" }}
            // style={{direction:'ltr'}}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              // ToolBar header
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              headerStyle: {
                ...stElemnt.AF.header,
                backgroundColor: colors.grey[100],
                fontWeight: "bold",
                paddingTop: "2px",
                paddingBottom:"2px",
                border: "solid 0px",
                border: "solid 0.1px",
               
                
              },
              paging: false,
             // headerStyle: { ...stElemnt.AF.header, backgroundColor: "grey" },
              cellStyle: {
                ...stElemnt.AF.cell,
                padding: "0px 1px",
                // fontSize: 8,
              },
              // maxBodyHeight: isFullPage ? "56.3rem" : "35.6rem",
              // minBodyHeight: isFullPage ? "56.3rem" : "35.6rem",
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h5 style={{ color: "GrayText" }}>
                    لا توجد ارصدة بعد في هذا الحساب
                  </h5>
                ),
              },
            }}
            columns={columns}
            data={data2}
          />
          {isLast && <Totals data={data ?? []} />}
        </Grid>
        {/* <Grid item xs={12}>
        </Grid> */}
      </Grid>
      {/* <Grid container lg={12} md={12} style={{ direction: "rtl",paddingTop:"0.5rem"}}>
        <Grid container xs={12} className={cls.rootinfAcc}>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"المدير العام"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"المدير المالي"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"المراجع"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"المحاسب"}
          </Grid>
          <Grid item xs={4} className={[cls.infAcc]}>
            {"المستخدم"}
          </Grid>
        </Grid>
        <Grid container xs={12} className={[cls.rootinfAcc, cls.downinfAcc]}>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"--------------------"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"--------------------"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"--------------------"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"--------------------"}
          </Grid>
          <Grid item xs={4} className={[cls.infAcc]}>
            {""}
          </Grid>
        </Grid>

      </Grid> */}
      
    </Grid>
  );
}

export default BodyRest;
