import React, { useState, useEffect } from "react";

import {  selectDateOrTime } from "../../../../../services/saleAnalysis-services";
import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import SalesDetialsType from "./SalesDetialsType";
import SalesDetailsForm from "./SalesDetailsForm";
import SalesDetailsTable from "./SalesDetailsTable";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import DailyReportFilter from "../EveryDay/DailyReportFilter";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.green[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  purchTotalCard: {
    backgroundColor: colors.blue[50],
    borderColor: colors.blue[300],
  },
}));

export default function SalesDetails(props) {
  const { AutoOptions, CustomerOptions, branch, setBranch, valueType,setValueType ,isPurches} = props;

   const SalesDetailsDefouls = {
    //selectType: selectTypes[0],
    dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    timeFrom: moment(new Date()).format('HH:mm'),
    dateTo: moment(new Date()).format('YYYY-MM-DD'),
    timeTo: moment(new Date()).format('HH:mm'),
    selectTime:selectDateOrTime[0],
    cust: { id: -1, name: "الكل" },
    type: { id: -1, name: "الكل" },
    branch: { id: -1, name: "الكل" },
  };
  
  const [rptParams, setRptParams] = React.useState(SalesDetailsDefouls);
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);
  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `RptSales/SalesDetails?sdate=${rptParams.dateFrom}
        &&edate=${rptParams.dateTo}
    &&dtype=${rptParams.type.id}
    &&branch=${branch?.id}
    &&customers=${rptParams.cust.id}`,
    // mapFunction: toFixedValues,
      dependencyArray: [],
    },
  });
  const his = useHistory();
  const printHandle = () => {
    const info = {
      data: data?.salesDetials,
      tot: data?.totals,
      from: "طريقة الدفع",
      title: "تقرير تفصيلي للمبيعات",
      auto: {},
      fDate: rptParams.dateFrom,
      tDate: rptParams.dateTo,
      nDate: moment(new Date()).format("YYYY-MM-DD"),
      ElmntShow: true,
      typeName:rptParams.type.name,
      branch:branch?.name,
      custName:rptParams.cust.name,
      //totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
    };
    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/ReviewTafsel" });
    window.location.reload(true);
  };

  const printHandle2 = () => {
    const info = {
      // ...rptParams,
      data: data?.salesDetials,
      tot: data?.totals,
      from: "طريقة الدفع",
      title: "تقرير تفصيلي للمبيعات",
      typeName:valueType?.name,
      branch:branch?.name,
      custName:rptParams.cust.name,
    };
 
    his.push({ pathname: "/ReviewTafsel", state: info });

    window.location.reload(false);
  };
  let excel_name="SalesDetailsReports";

  const excelHeaders = [
    { label: "الصنف", key: "symbol" },
    { label: "الكمية", key: "val1" },
    { label: "السعر", key: "exchange_price" },
    { label: "الخصم", key: "discount" },
    { label: "الضريبة", key: "taxval" },
    { label: "الصافي مع الضريبة", key: "amount" },
    { label: "العميل", key: "customer_name" },
    { label: "التفاصيل", key: "trans_name_ar" },
    { label: "طريقة الدفع", key: "name" },
  ];

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
         {"تقرير تفصيلي للمبيعات"}
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor:colors.green[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <DailyReportFilter {...{ rptParams, setRptParams ,isPurches}} />
          <SalesDetailsForm
            {...{
              AutoOptions,
              CustomerOptions,
              rptParams,
              onChangeData,
              onChangeAutoData,
              printHandle,
              branch,
              setBranch,
              valueType,
              setValueType,
              isPurches
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={
                  ( inshst.sal.iconBtn,
                  { borderRadius: 0 })
                }
                className={[clasui.button]}
                onClick={() => fetchURL()}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            <Grid item >
              {data && !fetching && data.salesDetials && (
                <CSVLink
                  separator={";"}
                  filename={excel_name}
                  data={data.salesDetials}
                  headers={excelHeaders}
                >
                  {" "}
                  <Button
                    disabled={fetching}
                    variant="contained"
                    startIcon={<TableChart />}
                    size="large"
                    color="primary"
                    style={{ borderRadius: 0 }}
                    className={[clasui.button]}
                  >
                    {"تصدير اكسل"}{" "}
                  </Button>
                </CSVLink>
              )}
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={
                  ( inshst.sal.iconBtn,
                  { borderRadius: 0 })
                }
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={
                  ( inshst.sal.iconBtn,
                  { borderRadius: 0 })
                }
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
          <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: null,
                  title: "إجمالي مبيعات",
                  value:  data?.totals?.tot_sales.toFixed(2),
                }}
              />
            </Grid>

            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className:null,
                  title:"إجمالي المرتجع",
                  value: data?.totals?.tot_return.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: null,
                  title:" الإجمالي قبل الضريبة",
                  value: data?.totals?.betfore_vat.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: null,
                  title: "إجمالي الضريبة",
                  value: data?.totals?.vatTotal.toFixed(2),
                }}
              />
            </Grid>
            
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: null,
                  title: " الإجمالي مع الضريبة",
                  value:  data?.totals?.netAmount.toFixed(2),
                }}
              />
            </Grid>

            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className:null,
                  title:"إجمالي الصافي",
                  value: data?.totals?.netAmount.toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.green[300]} />
              {!fetching && (
                <SalesDetailsTable
                  {...{
                    data: data?.salesDetials ? data.salesDetials : [],
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
