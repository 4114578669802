import React, { useState, useEffect } from "react";

import { SalesDetailsDefouls, selectDateOrTime } from "../../../../../services/saleAnalysis-services";
import {
    Button,
    Chip,
    colors,
    Container,
    Grid,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import SalesCloseForm from "./CloseReportForm";
import SalesCloseTable from "./CloseReprtTables";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
    useStylesHed,
    useStylesSpDial,
    useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import toast from "react-hot-toast";
// import { SearchCloseReports } from "../../../../../services/accMasn-services";
import { sto } from "../../../../../Context/InshData";

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
    },
    itemW: {
        // display:'flex',
        width: "90%",
    },
    headerTitle: {
        backgroundColor: colors.green[50],
        textAlign: "center",
        fontSize: 20,
        width: "100%",
    },

    allWidth: {
        minWidth: 650,
    },
    checStyl: {
        marginTop: "17px",
        fontSize: "16px",
        padding: "-1px",
    },
    purchTotalCard: {
        backgroundColor: colors.blue[50],
        borderColor: colors.blue[300],
    },
}));

export default function CloseReports(props) {
    const { AutoOptions} = props;
    let idSafe=AutoOptions.Safes[0].id;
    let nameSafe=AutoOptions.Safes[0].name;

    let iduser=AutoOptions.Users[0].id;
    let nameuser=AutoOptions.Users[0].name;

    let idstock=AutoOptions.Stocks[0].id;
    let namestock=AutoOptions.Stocks[0].name;
     const SearchCloseReports = {
        transFrom: -1,
         transTo: -1,
        empFrom: -1, 
        empTo: -1,
        dateFrom: moment(new Date()).format('YYYY-MM-DD'),
        timeFrom: moment(new Date()).format('HH:mm'),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
        timeTo: moment(new Date()).format('HH:mm'),
        empName: '',
        safe: { id: sto.User_typei==1?-1:idSafe, name:sto.User_typei==1? "الكل":nameSafe },
        stock:{ id:sto.User_typei==1? -1:idstock, name:sto.User_typei==1?"الكل":namestock },
        user:{ id:sto.User_typei==1? -1:iduser, name:sto.User_typei==1? "الكل" :nameuser},
        type: 1,
        branch:{ id: -1, name: "الكل" },
        IdCom: -1,
        emp_id: -1,
        selectTime:selectDateOrTime[0],
      };
    const [rptParams, setRptParams] = React.useState(SearchCloseReports);
    const classes = useStyles();
    const clasui = useStylesUiElemment();
    const clasSp = useStylesSpDial();
    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [sum, setSum] = useState(false);
    const { t } = useTranslation(["forms", "translation"]);

    const viewHandle = async () => {

        setData([]);
        setFetching(true);
        await axios
          .get(
            uri +
            `RptSales/SalesClosed?trans_noFrom=${rptParams.transFrom}
        &&trans_noTo=${rptParams.transTo}
          &&emp_noFrom=${rptParams.empFrom}
        &&emp_noTo=${rptParams.user.id}
        &&emp_name=${rptParams.empName}
        &&trans_dateFrom=${rptParams.dateFrom+' '+rptParams.timeFrom}
        &&trans_dateTo=${rptParams.dateTo+' '+rptParams.timeTo}
    &&branch=${rptParams.branch.id}
    &&IdCom=${rptParams.IdCom}
    &&safe=${rptParams.safe.id}
    &&stock=${rptParams.stock.id}
    &&type=${rptParams.type}`
          )
          .then((res) => {
            setData(res.data);
            console.log(" the data is", res.data);
            setFetching(false);
          })
          .catch((err) => {
            setFetching(false);
            console.log(err);
            // toast.err("يوجد خطأ في الجلب");
            setData([]);
          });
      };

    const his = useHistory();
    
  var columns = [
    {title: "", field: "name", },
    {title: "", field: "TotalFloat",},
    // {title: "دائن", field: "TypeOp",headerStyle: headerS,cellStyle: cellS,},
  ];
    const printHandle = () => {

        const info = {
            columns:columns,
            // rout:rpt.parms,
            data: data?.salesClose,
            tot: data?.totals,
            from:'الإقفال اليومي',
            title:'تقرير اليومية',
            auto:rptParams,
            fDate:rptParams.dateFrom,tDate:rptParams.dateTo,ElmntShow:true,
            // totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
            }
          
        localStorage.setItem("iInfos", JSON.stringify(info));
        his.push({ pathname: "/ReviewEqfal" });
        window.location.reload(true);
    };

    let excel_name = "SalesDetailsReports";

    const excelHeaders = [
        { label: "الصنف", key: "symbol" },
        { label: "الكمية", key: "val1" },
        { label: "السعر", key: "exchange_price" },
        { label: "الخصم", key: "discount" },
        { label: "الضريبة", key: "taxval" },
        { label: "الصافي مع الضريبة", key: "amount" },
        { label: "العميل", key: "customer_name" },
        { label: "التفاصيل", key: "trans_name_ar" },
        { label: "طريقة الدفع", key: "name" },
    ];
    const changTField = (prop) => (e) => {
        setRptParams({ ...rptParams, [prop]: e.target.value });

       // prop=='dateFrom' ? rptParams.dateFrom=e.target.value : rptParams.dateTo=e.target.value
      };
    const onChangeData = (propName) => (event) => {
        setRptParams({ ...rptParams, [propName]: event.target.value });
    };
    const onChangeAutoData = (propName, newValue) => {
        setRptParams({ ...rptParams, [propName]: newValue });
    };
    return (
        <div className={classes.root}>
            <Grid container item xs={12}>
                <Typography
                    size="small"
                    className={classes.headerTitle}
                    color="primary"
                >
                    {"تقرير الإقفال اليومي"}
                </Typography>
            </Grid>
            <Typography
                component="div"
                style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
            >
                <Grid container lg={12} md={12} sm={12} xm={12} className={clasui.rootchip}>

                    <Grid container lg={6} md={6} sm={6} xm={12} className={clasui.rootchip}>

                        <Container
                            component={Paper}
                            maxWidth={false}
                            style={{
                                padding: "10px",
                                paddingBottom: "0px",
                                borderBottomColor: colors.green[300],
                                borderBottomWidth: "1px",
                                borderBottomStyle: "solid",
                            }}
                        >
                            <SalesCloseForm
                                {...{
                                    AutoOptions,
                                    rptParams,
                                    onChangeData,
                                    onChangeAutoData,
                                    changTField,
                                }}
                            />
                            <Grid
                            item
                                container

                                justifyContent="center"
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                spacing={1}
                            >
                                <Grid item>
                                    <Button
                                        disabled={fetching}
                                        variant="contained"
                                        startIcon={<ScannerIcon />}
                                        size="large"
                                        style={
                                            (inshst.sal.iconBtn,
                                                { borderRadius: 0 })
                                        }
                                        className={[clasui.button]}
                                        onClick={() => viewHandle()}
                                    >
                                        {t("forms:view")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={fetching}
                                        variant="contained"
                                        startIcon={<PrintIcon />}
                                        color="primary"
                                        size="large"
                                        className={[clasui.button, clasSp.clSec]}
                                        style={
                                            (inshst.sal.iconBtn,
                                                { borderRadius: 0 })
                                        }
                                        onClick={printHandle}
                                    >
                                        {t("forms:print")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        startIcon={<KeyboardReturnSharpIcon />}
                                        color="secondary"
                                        size="large"
                                        className={[clasui.button, clasSp.clcan]}
                                        style={
                                            (inshst.sal.iconBtn,
                                                { borderRadius: 0 })
                                        }
                                        onClick={() => his.goBack()}
                                    >
                                        {t("forms:exit")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container maxWidth={true} style={{ padding: "4px" }}>
                            <Grid container spacing={1} style={{ paddingTop: "10px" }}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <TotalCard
                                        {...{
                                            className: null,
                                            title: "صافي المبيعات",
                                            value: data?.totals?.totSal.toFixed(2),
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <TotalCard
                                        {...{
                                            className: null,
                                            title: "إجمالي الآجل",
                                            value: data?.totals?.totAjel.toFixed(2),
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <TotalCard
                                        {...{
                                            className: null,
                                            title: "صافي شبكة + بنك",
                                            value: data?.totals?.totNetAndBank.toFixed(2),
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <TotalCard
                                        {...{
                                            className: null,
                                            title: "صافي نقدي",
                                            value: data?.totals?.totNetAmount.toFixed(2),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                        <br />
                    </Grid>
                    <Grid container lg={6} md={6} sm={6} xm={12} className={clasui.rootchip}>
                        <Container
                            component={Paper}
                            maxWidth={false}
                            style={{
                                padding: "10px",
                            }}
                        >
                            <Grid container justifyContent="center">
                                <Grid item container justifyContent="center" xs={12}>
                                    <BeatLoader loading={fetching} color={colors.green[300]} />
                                    {!fetching && (
                                        <SalesCloseTable
                                            {...{
                                                data: data?.salesClose ? data.salesClose : [],
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
            </Typography>
        </div>
    );
}
