import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import CloseReports from "./ClosaReport";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../../services/LoadingPage";
const defaultAutos = {
    Safes: [],
};

export default function CloseReportsCon() {

  const [branch, setBranch] = useState({ id: -1, name: "الكل" });
  const tb = {
    wait: true,
    Safes: true,
    Users: true,
    Stocks: true,
    Branches: true,
    stor: { ...sto, branchi: branch.id == -1 ? sto.branchi : branch.id },
    parms:{
      safe:-1, 
    }
};
// const url = uri + "Auto" + "/type";
// const { data: TypesOptions, fetching: fetchCust } = useFetchURL({
//   ...{ url, dependencyArray: [] },
// });
  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb,
    [branch],

  );

  if (
    fetchAll ||
    !AutoOptions?.Safes 
  )
    return <LoadingPage />;

  return (
    <CloseReports
      {...{
        AutoOptions: AutoOptions?.Safes ? AutoOptions : defaultAutos,
        branch,
        setBranch,

      }}
    />
  );
}
