import React, { useState, useEffect } from "react";

import { SAdefaultValues, selectDateOrTime } from "../../../../../services/saleAnalysis-services";
import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import DailyReportFilter from "../EveryDay/DailyReportFilter";
import ActivityReportsForm from "./ActivitySalesReportsForm";
import ActivityReportsTable from "./ActivityReportsTable";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
// import { SearchActivityReports } from "../../../../../services/accMasn-services";
import moment from "moment";
import axios from "axios";
import { sto } from "../../../../../Context/InshData";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.green[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  purchTotalCard: {
    backgroundColor: colors.blue[50],
    borderColor: colors.blue[300],
  },
}));

export default function ActivityReports(props) {
  const { AutoOptions, isPurches } = props;
  let idSafe=AutoOptions.Safes[0].id;
  let nameSafe=AutoOptions.Safes[0].name;
   const SearchActivityReports = {
    dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    timeFrom: moment(new Date()).format('HH:mm'),
    dateTo: moment(new Date()).format('YYYY-MM-DD'),
    timeTo: moment(new Date()).format('HH:mm'),
    safe:{id:sto.User_typei==1?-1:idSafe, name: sto.User_typei==1?'الكل':nameSafe},
    user:{ id: -1, name: "الكل" },
    type: 1,
    branch:{ id: -1, name: "الكل" },
    IdCom: -1,
    sProTyInvPro:"02",
    curr: {id:-1, name:''},
    selectTime:selectDateOrTime[0],
  };

  const [rptParams, setRptParams] = React.useState({...SearchActivityReports ,
    sProTyInvPro:isPurches?"01":"02",
  });
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [sum, setSum] = useState(false);
  const viewHandle = async () => {

    setData([]);
    setFetching(true);
    await axios
      .get(
        uri +
        `RptSales/ActivityReport?dateFrom=${rptParams.selectTime.id==1?rptParams.dateFrom:rptParams.dateFrom+' '+rptParams.timeFrom}
        &&dateTo=${rptParams.selectTime.id==1?rptParams.dateTo:rptParams.dateTo+' '+rptParams.timeTo}
    &&branch=${rptParams.branch.id}
    &&safe=${rptParams.safe.id}
    &&item=${rptParams.curr.id}
    &&type=${rptParams.type}
    &&sProTyInvPro=${rptParams.sProTyInvPro}`
      )
      .then((res) => {
        setData(res.data);
        console.log(" the data is", res.data);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
        console.log(err);
        // toast.err("يوجد خطأ في الجلب");
        setData([]);
      });
  };

  const his = useHistory();
  const printHandle = () => {
    if (data.length ==0){
      alert("There is no data in table");
    }else{
    const info = {
      data: data?.activityData,
      tot: data?.totals,
      from: "النشاط التجاري",
      title: isPurches?"تقرير النشاط التجاري / مشتريات" :"تقرير النشاط التجاري / مبيعات",
      tot1:isPurches?"إجمالي (المشتريات)":"إجمالي (المبيعات)",
      tot2: isPurches?"إجمالي الضريبة (المشتريات)":"إجمالي الضريبة (المبيعات)",
      tot3: isPurches?"إجمالي النهائي (المشتريات)":"إجمالي النهائي (المبيعات)",
      fDate:rptParams.selectTime.id==1?rptParams.dateFrom:rptParams.dateFrom+' '+rptParams.timeFrom,
      tDate:rptParams.selectTime.id==1?rptParams.dateTo:rptParams.dateTo+' '+rptParams.timeTo,
      nDate: moment(new Date()).format("YYYY-MM-DD"),
      branch:rptParams.branch.name,
      safe:rptParams.safe.name,
      curr:rptParams.curr.name,
    };
    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/ReviewActivity" });
    window.location.reload(true);
  }
  };

  let excel_name=isPurches ? "PurchasesActivityReports" : "SalessActivityReports";
  const excelHeaders = [
    { label: "المخزن", key: "safe" },
    { label: "نوع العملية", key: "optype" },
    { label: "التاريخ", key: "ddate" },
    { label: "رقم الفاتورة", key: "bill_no" },
    { label: "الصنف", key: "item_name" },
    { label: "الكمية", key: "qty" },
    { label: "السعر", key: "price" },
    { label: "الضريبه", key: "tax" },
    { label: "الاجمالي", key: "total" },
    { label: "المستخدم", key: "uuser" },
    // {title: "التقاصيل", field: "",},
  ];

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          {isPurches? "تقرير النشاط التجاري مشتريات" : "تقرير النشاط التجاري مبيعات"} 
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: isPurches ? colors.blue[300] : colors.green[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <DailyReportFilter {...{ rptParams, setRptParams, isPurches }} />
          <ActivityReportsForm
            {...{
              AutoOptions,
              rptParams,
              onChangeData,
              onChangeAutoData,
              isPurches,
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={
                  (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                  { borderRadius: 0 })
                }
                className={[clasui.button]}
                onClick={() => viewHandle()}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            <Grid item >
              {data && !fetching && data.activityData && (
                <CSVLink
                  separator={";"}
                  filename={excel_name}
                  data={data.activityData}
                  headers={excelHeaders}
                >
                  {" "}
                  <Button
                    disabled={fetching}
                    variant="contained"
                    startIcon={<TableChart />}
                    size="large"
                    color="primary"
                    style={{ borderRadius: 0 }}
                    className={[clasui.button]}
                  >
                    {"تصدير اكسل"}{" "}
                  </Button>
                </CSVLink>
              )}
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={
                  (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                  { borderRadius: 0 })
                }
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={
                  (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                  { borderRadius: 0 })
                }
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
          <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title:"إجمالي عدد السجلات",
                  value: data?.totals?.countRow,
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title:isPurches?"إجمالي (المشتريات)":"إجمالي (المبيعات)",
                  value: data?.totals?.totSal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: isPurches?"إجمالي الضريبة (المشتريات)":"إجمالي الضريبة (المبيعات)",
                  value: data?.totals?.totTax.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: isPurches?"إجمالي النهائي (المشتريات)":"إجمالي النهائي (المبيعات)",
                  value: data?.totals?.totFinalSal.toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.green[300]} />
              {!fetching && (
                <ActivityReportsTable
                  {...{
                    data: data?.activityData ? data.activityData : [],
                    isPurches,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
