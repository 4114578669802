import React, { useState,useEffect } from 'react';
import axios from 'axios';
import {uri, }  from '../../../../help/Api';
import {Rept, sto}  from '../../../../Context/InshData';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {useRptAF} from '../../../../St/Reviews/UseStyle';

 function FooterA(found) {
  const cls = useRptAF();
  const [state, setState] = useState({});
    return (
      <Grid item container 
      
      style={{
        textAlign: "center",
        direction: "rtl",
        fontFamily: "Amiri-Regular",
      }}>

     <Grid container>
      <Grid xs={12}>
        {" "}
        <div className={cls.line}></div>{" "}
      </Grid>
      <Grid xs={12}>
        {" "}
        <div className={cls.titleF}>{found.Address}</div>{" "}
      </Grid>

      <Grid item container className={cls.subF} xs={12}>
        <Grid xs={1} className={cls.infoF}>
          <div>هاتف:</div>
        </Grid>
        <Grid xs={3} className={cls.infoF}>
          <div>{found.Tel}</div>
        </Grid>
        <Grid xs={1} className={cls.infoF}>
          <div>مبايل:</div>
        </Grid>
        <Grid xs={3} className={cls.infoF}>
          <div>{found.Mobile}</div>
        </Grid>
        <Grid xs={1} className={cls.infoF}>
          <div>فاكس:</div>
        </Grid>
        <Grid xs={3} className={cls.infoF}>
          <div>{found.Fax}</div>
        </Grid>
      </Grid>
     
    </Grid>
      </Grid>        
    );
  }
export default FooterA;


