import {
  colors,
  FormControl,
  FormLabel,
  Grid,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MaterialTable from "material-table";
import React, { Fragment, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import { sto } from "../../../../Context/InshData";
import { uri } from "../../../../help/Api";
import { cellS, headerB, headerS } from "../../../../St/comps/cStyle";
import { getLS } from "../../../../utils/useLS";
import Button from "../../../../components/controls/Button";
import ScannerIcon from "@material-ui/icons/Scanner";
const ItemsList = (props) => {
  const locSto = getLS("sto", {});
 
  const tb = {
    wait: true,
    Currencies: true,
    groups: true,
    parms: {
      scurrenciestype: "C",
      tname: "curr",
      iempid: sto.empi,
      takei: 50,
      sSearch: "",
      group_id: locSto.groups[0].id==null?null:locSto.groups[0].id,
    },
    autos: { allGroups: true },
  };
  const [searchArgs, setSearchArgs] = useState({
    barcode: "",
    name: "",
    group: { id:locSto.groups[0].id, name: locSto.groups[0].name },
  });

  const {
    object: rows,
    fetching,
    fetch,
  } = useFetchObjectByPost(uri + "Res", tb);

  const { t } = useTranslation(["forms"]);
  const { items, actionItem,gets } = props;
  var columns = [
    { title: t("forms:product-id"), field: "id" },
    { title: t("forms:product-name-ar"), field: "symbol" },
    { title: t("forms:barcode"), field: "barcode" },
    { title: t("forms:product-group"), field: "groupName" },
    { title: t("forms:purch-price"), field: "purch_price" },
    { title: t("forms:sale-price"), field: "sale_price" },
  ];

  useEffect(() => {
    fetch();
  }, [items]);

  const filteredRows = useMemo(() => {
    console.log(rows);
    if (!searchArgs?.group && !searchArgs?.name && !searchArgs?.barcode)
      return rows?.Currencies;
    console.log(searchArgs?.name);
    if (rows?.Currencies?.length > 0) {
      let list = rows?.Currencies;
      if (searchArgs?.group)
        list = list?.filter((obj) => obj.group_id == searchArgs?.group?.id);
      if (searchArgs?.name)
        list = list?.filter((obj) =>
          JSON.stringify(obj.symbol)
            .toLowerCase()
            .includes(searchArgs?.name?.toLowerCase())
        );

      if (searchArgs?.barcode)
        list = list?.filter((obj) =>
          JSON.stringify(obj.barcode)
            .toLowerCase()
            .includes(searchArgs?.barcode?.toLowerCase())
        );

      return list;
    }
    return [];
  }, [searchArgs, rows, items]);

  return (
    <Fragment>
      <Grid item container xs={12} spacing={1} style={{ padding: 5 }}>
        <Grid item md={3} sm={4} xs={4}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="id"
            label={t("forms:barcode")}
            //className={clasTF.rootEDS}
            style={{ backgroundColor: colors.red[50], marginBlock: 10 }}
            // InputProps={{ className: clasTF.input }}
            name="id"
            value={searchArgs.barcode}
            onChange={(e) =>
              setSearchArgs({ ...searchArgs, barcode: e.target.value })
            }
          />
        </Grid>
        <Grid item md={3} sm={4} xs={4}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="id"
            label={t("forms:product-name-ar")}
            //className={clasTF.rootEDS}
            style={{ backgroundColor: colors.red[50], marginBlock: 10 }}
            // InputProps={{ className: clasTF.input }}
            name="id"
            value={searchArgs.name}
            onChange={(e) =>
              setSearchArgs({ ...searchArgs, name: e.target.value })
            }
          />
        </Grid>

        <Grid item md={3} sm={3} xs={3}>
          <Autocomplete
            value={searchArgs.group}
            // multiple
            labelPlacement=""
            onChange={(event, newValue) => {
              setSearchArgs({ ...searchArgs, group: newValue });
              // changAutos('group_id',newValue)
            }}
            id="controllable-states-demo"
            size="small"
            style={{ backgroundColor: colors.red[50], marginBlock: 10 }}
            // options={rows?.groups ?? []}
            options={gets.groups}

            // locSto.groups[0]
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}
            // disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("forms:product-group")}
                variant="outlined"
                size="small"
                // InputLabelProps={{className:claslbl.clrSale}}
              />
            )}
          />
        </Grid>
        {/* <Grid item md={3} sm={3} xs={3}>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={{ backgroundColor: colors.red[50], marginBlock: 10,borderRadius: 0 }}
               
               // onClick={() => fetchURL()}
              >
                {t("forms:view")}
              </Button>
            </Grid> */}
        <Grid item md={3} sm={3} xs={3}></Grid>

        <Grid item xs={12}>
          {fetching ? (
            <LinearProgress />
          ) : (
            <MaterialTable
              style={{ boxShadow: "none" }}
              options={{
                pageSize: 7,
                //showTitle:false,
                toolbar: false,
                sorting: false,
                showTitle: false,
                paging: false,
                headerStyle: headerB,
                cellStyle: cellS,
                maxBodyHeight: "100vh",
                minBodyHeight: "100vh",
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <h3
                      style={{
                        marginTop: "20vh",
                        border: "solid 0px",
                        boxShadow: "none",
                      }}
                    ></h3>
                  ),
                },
              }}
              columns={columns}
              data={filteredRows}
              onRowClick={(event, rowData) => {
                actionItem(rowData);

                event.stopPropagation();
              }}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ItemsList;
