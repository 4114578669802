import React, { useState, useEffect } from "react";

import {
  Button,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { BeatLoader } from "react-spinners";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import {
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { sto } from "../../../../../Context/InshData";
import DailyReportFilter from "../EveryDay/DailyReportFilter";
import {  selectDateOrTime, selectTypes } from "../../../../../services/saleAnalysis-services";
import axios from "axios";
import EmpSalesForms from "./EmpSalesFom";
import SalesEmpTable from "./SalesEmpTable";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import toast from "react-hot-toast";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },
  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
}));

export default function EmpSales(props) {
  const { AutoOptions, CustomerOptions, isSale, isPurches } = props;
  let idemp=AutoOptions.Employees[0].id;
  let nameemp=AutoOptions.Employees[0].name;
   const sealetedEmpValue = {
    selectType: selectTypes[0],
    selectTime:selectDateOrTime[0],
    dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    timeFrom: moment(new Date()).format('HH:mm'),
    dateTo: moment(new Date()).format('YYYY-MM-DD'),
    timeTo: moment(new Date()).format('HH:mm'),
    cust: { id: -1, name: "الكل" },
    type: { id: -1, name: "الكل" },
    branch: { id: -1, name: "الكل" },
    emp: { id:sto.User_typei==1? -1:idemp, name:sto.User_typei==1? "الكل":nameemp },
    branch: { id: -1, name: "الكل" },
    dateTime: "false",
    systemName:"sale",
    receiveBill: -1,
    user: -1,
  };
  const [rptParams, setRptParams] = React.useState({ ...sealetedEmpValue, systemName: isSale ? "sale" : "Purches" });
  const { t } = useTranslation(["forms", "translation"]);
  // const his = useHistory();
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [sum, setSum] = useState(false);

  const viewHandle = async () => {

    setData([]);
    setFetching(true);
    await axios
      .get(
        uri +
        `RptSales/EmpReports?branche=${rptParams.branch.id}
          &&fDate=${rptParams.selectTime.id == 1 ? rptParams.dateFrom : rptParams.dateFrom + ' ' + rptParams.timeFrom}
      &&tDate=${rptParams.selectTime.id == 1 ? rptParams.dateTo : rptParams.dateTo + ' ' + rptParams.timeTo}
  &&date_time=${rptParams.dateTime}
  &&sales_emp=${rptParams.emp.id}
  &&system_Name=${rptParams.systemName}
  &&user_id=${rptParams.user}
  &&cust_id=${rptParams.cust.id}
  &&recieve_bill=${rptParams.receiveBill}`
      )
      .then((res) => {
        setData(res.data);
        console.log(" the data is", res.data);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
        console.log(err);
        // toast.err("يوجد خطأ في الجلب");
        setData([]);
      });
  };
  const his = useHistory();


  const printHandle = () => {
    if (data.length ==0){
      alert("There is no data in table");
    }else{
    const info = {
      data: data?.salesEmp,
      tot: data?.totals,
      fromH:"الموظف",
      title:  isSale ? t("translation:sale-employee") : t("translation:purch-employee") + " / " + rptParams.emp?.name,
      tot1:isPurches?"إجمالي (المشتريات)":"إجمالي (المبيعات)",
      tot2: isPurches?"إجمالي الضريبة (المشتريات)":"إجمالي الضريبة (المبيعات)",
      tot3: isPurches?"إجمالي النهائي (المشتريات)":"إجمالي النهائي (المبيعات)",
      fDate:rptParams.selectTime.id==1?rptParams.dateFrom:rptParams.dateFrom+' '+rptParams.timeFrom,
      tDate:rptParams.selectTime.id==1?rptParams.dateTo:rptParams.dateTo+' '+rptParams.timeTo,
      nDate: moment(new Date()).format("YYYY-MM-DD"),
      branch:rptParams.branch.name,
      fromname:rptParams.emp.name,
    };
    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/SalesManPrint" });
    window.location.reload(true);
  }
  };

  let excel_name = isPurches ? "EmpPurchasesReport" : "EmpSalesReports";
  const excelHeaders = [
    { label: " نوع الحركة", key: "Doc_Name", },
    { label:"التاريخ", key: "date", },
    { label:"رقم الفاتورة", key: "id", },
    { label:"الصنف", key: "symbol", },
    { label:"الكمية", key: "val", },
    { label:"السعر", key: "exchange_price", },
    { label: "الضريبه", key: "taxval", },
    { label:"الاجمالي", key: "sum_Price", },
  ];

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          style={{
            backgroundColor: isSale ? colors.green[50] : colors.indigo[50],
          }}
        // color="primary"
        >
          {isSale
            ? t("translation:sale-employee")
            : t("translation:purch-employee")}
        </Typography>
      </Grid>
      <Typography component="div" style={{ direction: "rtl" }}>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            borderBottomColor: colors.indigo[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12} justifyContent="center">
            <DailyReportFilter {...{ rptParams, setRptParams, isPurches }} />
          </Grid>
          <EmpSalesForms
            {...{ AutoOptions, rptParams, onChangeData, onChangeAutoData, CustomerOptions, isPurches }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={isSale ? inshst.sal.iconBtn : inshst.purch.iconBtn}
                className={[clasui.button]}
                onClick={() => viewHandle()}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            <Grid item >
              {data && !fetching && data.salesEmp && (
                <CSVLink
                  separator={";"}
                  filename={excel_name}
                  data={data.salesEmp}
                  headers={excelHeaders}
                >
                  {" "}
                  <Button
                    disabled={fetching}
                    variant="contained"
                    startIcon={<TableChart />}
                    size="large"
                    color="primary"
                    style={{ borderRadius: 0 }}
                    className={[clasui.button]}
                  >
                    {"تصدير اكسل"}{" "}
                  </Button>
                </CSVLink>
              )}
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={isSale ? inshst.sal.iconBtn : inshst.purch.iconBtn}
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={isSale ? inshst.sal.iconBtn : inshst.purch.iconBtn}
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
            {/* <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: "الإجمالي",
                  value: data
                    .reduce((a, v) => (a = a + v.sum_Price), 0)
                    ?.toFixed(2),
                }}
              />
            </Grid> */}
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: "إجمالي عدد السجلات",
                  value: data?.totals?.countRow,
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: isPurches ? "إجمالي (المشتريات)" : "إجمالي (المبيعات)",
                  value: data?.totals?.totSal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: isPurches ? "إجمالي الضريبة (المشتريات)" : "إجمالي الضريبة (المبيعات)",
                  value: data?.totals?.totTax.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: isPurches ? "إجمالي النهائي (المشتريات)" : "إجمالي النهائي (المبيعات)",
                  value: data?.totals?.totFinalSal.toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader
                loading={fetching}
                color={isSale ? colors.green[300] : colors.indigo[300]}
              />
              {!fetching && (
                <SalesEmpTable
                  //{...{ data: data?.length > 0 ? data : [], isSale }}
                  {...{
                    data: data?.salesEmp ? data.salesEmp : [],
                    isPurches,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
