import React, { useState, useEffect } from "react";

import { SAdefaultValues } from "../../../../../services/saleAnalysis-services";
import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import VatReportForm from "./VatReportForm";
import VatReportTable from "./VatReportTable";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import {
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { TableChart } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import { SearchVatSalesReports } from "../../../../../services/accMasn-services";
import moment from "moment";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import VatReportFilter from "./VatReportsFilter";
import DailyReportFilter from "../EveryDay/DailyReportFilter";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.green[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  purchTotalCard: {
    backgroundColor: colors.blue[50],
    borderColor: colors.blue[300],
  },
}));

export default function VatReports(props) {
  const { AutoOptions, CustomerOptions, isPurches } = props;
  const locat = useLocation();
  const [rptParams, setRptParams] = React.useState({ ...SearchVatSalesReports, reportType: isPurches ? 0 : 1, });
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [sum, setSum] = useState(false);
  const viewHandle = async () => {
    setData([]);
    setFetching(true);
    await axios
      .get(
        uri +
        `SalePurchVat?branch=${rptParams.branch.id}
    &&safe=${rptParams.safe.id}
    &&costCenter=${rptParams.costCenter.id}
    &&dateF=${rptParams.selectTime.id == 1 ? rptParams.dateFrom : rptParams.dateFrom + ' ' + rptParams.timeFrom}
    &&dateT=${rptParams.selectTime.id == 1 ? rptParams.dateTo : rptParams.dateTo + ' ' + rptParams.timeTo}
    &&cust_id=${rptParams.cust.id}
    &&itemsTypeVat=${rptParams.selectVatType.name}
    &&systemId=${rptParams.reportType}
    &&user_id=${rptParams.user.id}`
      )
      .then((res) => {
        setData(res.data);
        console.log(" the data is", res.data);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
        console.log(err);
        // toast.err("يوجد خطأ في الجلب");
        setData([]);
      });
  };

  const his = useHistory();
  const printHandle = () => {
    if (data.length == 0) {
      alert("There is no data in table");
    } else {
      const info = {
        data: data?.SalePurchVat,
        tot: data?.totals,
        from: "اللتقرير الضريبي",
        title: isPurches ? "التقرير الضريبي للمشتريات" : "التقرير الضريبي للمبيعات",
        tot1: isPurches ? "إجمالي(المشتريات-المردودات)  " : "إجمالي(المبيعات-المردودات) ",
        tot2: isPurches ? "إجمالي ضريبة(المشتريات-المردودات)" : "إجمالي ضريبة(المبيعات-المردودات)",
        tot3: isPurches ? "إجمالي(المشتريات-المردودات) بعد الضريبة" : "إجمالي(المبيعات-المردودات) بعد الضريبة",
        fDate: rptParams.selectTime.id == 1 ? rptParams.dateFrom : rptParams.dateFrom + ' ' + rptParams.timeFrom,
        tDate: rptParams.selectTime.id == 1 ? rptParams.dateTo : rptParams.dateTo + ' ' + rptParams.timeTo,
        nDate: moment(new Date()).format("YYYY-MM-DD"),
        branch: rptParams.branch.name,
        safe: rptParams.safe.name,
        cust: rptParams.cust.name,
        center: rptParams.costCenter.name,
        tot4:isPurches?"المشتريات الخاضعة للنسبة الاساسية":" المبيعات الخاضعة للنسبة الاساسية",
      };
      localStorage.setItem("iInfos", JSON.stringify(info));
      his.push({ pathname: "/TSP" });
      window.location.reload(true);
    }
  };

  let excel_name = isPurches ? "PurchasesVatReport" : "SalesVatReport";
  const excelHeaders = [
    { label: "تاريخ القيد", key: "Date", },
    { label: "القيد", key: "proc_id", },
    { label: "البيان/الشرح", key: "notes", },
    { label: "رقم الفاتورة", key: "bill_no", },
    { label: isPurches ? "المورد" : "العميل", key: "customer_name", },
    { label: "الملف الضريبي", key: "taxno", },
    { label: "المبلغ قبل الضريبة", key: "TotalBeforVat", },
    { label: "الضريبة", key: "TotalVat", },
    { label: "المبلغ بعد الضريبة", key: "TotalAfterVat", },
    { label: "", key: "", },
  ];


  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          {isPurches ? "التقرير الضريبي للمشتريات" : "التقرير الضريبي للمبيعات"}

        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: isPurches ? colors.blue[300] : colors.green[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <DailyReportFilter {...{ rptParams, setRptParams, isPurches }} />
          <VatReportForm
            {...{
              AutoOptions,
              rptParams,
              onChangeData,
              onChangeAutoData,
              isPurches,
              CustomerOptions,
            }}
          />
          <VatReportFilter {...{ rptParams, setRptParams, isPurches }} />

          <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingBottom: "0.1rem" }}>
            <p
              style={{
                backgroundColor: "#777777",
                padding: "2px",
                color: "#fff",
              }}
              color="default"
            >
              {isPurches ? 'هذا التقرير الضريبي بناء على فواتير المشتريات فقط .. وناتج التقرير بناء على ما تم إدخالة من قبل المستخدم حسب الإعدادات'
                : 'هذا التقرير الضريبي  بناء على فواتير المبيعات فقط .. وناتج التقرير بناء على ما تم إدخالة من قبل المستخدم حسب الإعدادات'}
            </p>
          </Grid>
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={
                  (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                    { borderRadius: 0 })
                }
                className={[clasui.button]}
                onClick={() => viewHandle()}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            <Grid item >
              {data && !fetching && data.SalePurchVat && (
                <CSVLink
                  separator={";"}
                  filename={excel_name}
                  data={data.SalePurchVat}
                  headers={excelHeaders}
                >
                  {" "}
                  <Button
                    disabled={fetching}
                    variant="contained"
                    startIcon={<TableChart />}
                    size="large"
                    color="primary"
                    style={{ borderRadius: 0 }}
                    className={[clasui.button]}
                  >
                    {"تصدير اكسل"}{" "}
                  </Button>
                </CSVLink>
              )}
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={
                  (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                    { borderRadius: 0 })
                }
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={
                  (isPurches ? inshst.purch.iconBtn : inshst.sal.iconBtn,
                    { borderRadius: 0 })
                }
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>

            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: " إجمالي عدد السجلات",
                  value: data?.totals?.rowsCount,
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: isPurches ? " صافي إجمالي قبل الضريبة" : " صافي إجمالي قبل الضريبة",
                  // title: isPurches ? "إجمالي(المشتريات-المردودات) قبل الضريبة" :"إجمالي(المبيعات-المردودات) قبل الضريبة",

                  value: data?.totals?.TotalBeforVat.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: isPurches ? " صافي إجمالي الضريبة" : " صافي إجمالي الضريبة",
                  value: data?.totals?.TotalVat.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  className: isPurches ? classes.purchTotalCard : null,
                  title: isPurches ? " صافي إجمالي بعد الضريبة" : " صافي إجمالي بعد الضريبة",
                  value: data?.totals?.TotalAfterVat.toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.green[300]} />
              {!fetching && (
                <VatReportTable
                  {...{
                    data: data?.SalePurchVat ? data.SalePurchVat : [],
                    isPurches,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
