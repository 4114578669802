import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

export default function SalesDetailsTable(props) {
  const { data, title } = props;


  
  const { t } = useTranslation(["forms"]);
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={{
              backgroundColor:colors.green[300],
              color: "#fff",
            }}
          >
            <TableCell style={{ color: "#fff" }} align="center">
              {"الصنف"}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {"الكمية"}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {"السعر"}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {"الخصم"}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {"الضريبة"}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {"الصافي مع الضريبة"}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {"العميل"}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {"التفاصيل"}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
                {"طريقة الدفع"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.val1}>
              <TableCell align="center">{row.symbol}</TableCell>
              <TableCell align="center">{row.val1}</TableCell>
              <TableCell align="center">{row.exchange_price?.toFixed(2)}</TableCell>
              <TableCell align="center">{row.discount?.toFixed(2)}</TableCell>
              <TableCell align="center">{row.taxval?.toFixed(2)}</TableCell>
              <TableCell align="center">{row.amount?.toFixed(2)}</TableCell>
              <TableCell align="center">{row.customer_name}</TableCell>
              <TableCell align="center">{row.trans_name_ar}</TableCell>
              <TableCell align="center">{row.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
